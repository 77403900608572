import React from 'react';
import Logo from '../../Assets/img/logo.png';
import { HeaderDiv } from './styles';

const Header = () => {
    
    return (
        <HeaderDiv>
            <div className="container">
                <h1>Heróis da Região <br/> Metropolitana de Campinas</h1>
                <div className="logo">
                    <img src={Logo} alt=""/>
                </div>
            </div>
        </HeaderDiv>
    )
}

export default  React.memo(Header);

import styled from 'styled-components';

export const Section = styled.section`
    .wrap{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px 0px;
        justify-items: center;
        width: 100%;
        height: auto;
        /* height: 180px; */

        @media (min-width: 768px){
            grid-template-columns: 1fr 1fr;
            grid-gap: 29px 16px;
            /* height: 600px; */
        }

        @media (min-width: 1200px){
            grid-template-columns: 1fr 1fr 1fr;
            /* height: 400px; */
        }
    }

`;
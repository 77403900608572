import React from 'react';
import { Section } from './styles';
import Carousel from 'react-bootstrap/Carousel';
import CardMessage from '../CardMessage';


const SlideshowMessage = ({data}) => {
    const [device, setDevice] = React.useState(null);
    const [countWrapper, setCountWrapper] = React.useState(null);
    const [countItens, setCountItens] = React.useState(1);
    const [controls, setControls] = React.useState(false);
    
    function deviceUse(){
        if (window.matchMedia("(min-width: 1200px)").matches){
            setDevice('desktop');
            setCountItens(3)
            return;
        }

        if (window.matchMedia("(min-width: 768px)").matches){
            setDevice('tablet');
            setCountItens(4)
            return;
        }

        setDevice('mobile');
    }

    function countWrapperData(){
        let qnt = countItens;
        const count = [];

        if(data){
            let val = Math.ceil(data.length / qnt);

            for(let i = 0; i < val; i++){
                count.push(i);
            }

            setCountWrapper(count)
        }
    }

    function validControls(){
        var width = window.innerWidth;

        if(data && data.length > 1 && width < 760)
            setControls(true);

        if(data && data.length > 4 && (width > 760 && width < 1200))
            setControls(true);

        if(data && data.length > 3 && width > 1200 )
            setControls(true);
    }

    React.useEffect(() => {
        validControls();   
        deviceUse()
        countWrapperData();

        window.addEventListener('resize', deviceUse)
        return () => window.removeEventListener("resize", deviceUse);
    }, [data, countItens]);


    if(device === 'mobile')
        return(
            <Section className="section-slideshow">
                <Carousel 
                    controls={controls}
                    className="carousel-standard" 
                    indicators={false} 
                    interval={null}
                >
                    {data && data.map( (el, index) => {
                        return (
                            <Carousel.Item key={index}>
                                 <div className="wrap">
                                    <CardMessage 
                                        title={el.data().name}
                                        city={el.data().city}
                                        content={el.data().content}
                                        socialMedia={el.data().socialmedia}
                                    />
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </Section>
        );

    if(device === 'tablet')
        return (
            <Section className="section-slideshow">
                <Carousel 
                    controls={controls}
                    className="carousel-standard" 
                    indicators={false} 
                    interval={null}
                >

                    {countWrapper && countWrapper.map((el, indexCarousel) => {

                        let init = 0;
                        let max = 4;
                        let prevCarouselCount = indexCarousel * max;

                        return (
                            <Carousel.Item key={`carousel-item-${indexCarousel}`}>
                                <div className="wrap">
                                    {data.map((el, index) => {
                                        if( (init < max) && ((index + 1) > prevCarouselCount) ) {
                                            init ++;
                                            prevCarouselCount++;

                                            return (
                                                <CardMessage 
                                                    key={index} 
                                                    title={el.data().name}
                                                    city={el.data().city}
                                                    content={el.data().content}
                                                    socialMedia={el.data().socialmedia}
                                                />
                                            )
                                        }

                                        return null;
                                    })}
                                </div>
                            </Carousel.Item>
                        )

                    })}
                </Carousel>
            </Section>
        );


    if(countWrapper && countWrapper.length > 0)
        return (
            <Section className="section-slideshow">
                <Carousel 
                    controls={controls}
                    className="carousel-standard" 
                    indicators={false} 
                    interval={null}
                >

                    {countWrapper && countWrapper.map((el, indexCarousel) => {

                        let init = 0;
                        let max = 3;
                        let prevCarouselCount = indexCarousel * max;

                        return (
                            <Carousel.Item key={`carousel-item-${indexCarousel}`}>
                                <div className="wrap">
                                    {data.map((el, index) => {
                                        if( (init < max) && ((index + 1) > prevCarouselCount) ) {
                                            init ++;
                                            prevCarouselCount++;

                                            return (
                                                <CardMessage 
                                                    key={index} 
                                                    title={el.data().name}
                                                    city={el.data().city}
                                                    content={el.data().content}
                                                    socialMedia={el.data().socialmedia}
                                                />
                                            )
                                        }

                                        return null;
                                    })}
                                </div>
                            </Carousel.Item>
                        )

                    })}
                </Carousel>
            </Section>
        );

    return null;
}


export default SlideshowMessage

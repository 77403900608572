import styled from 'styled-components';

export const Section = styled.section`
    .wrap{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px 0px;
        justify-items: center;
        align-items: flex-end;
        width: 100%;
        /* height: 110px; */
        height: auto;
        padding-top: 20px;

        @media (min-width: 768px){
            grid-template-columns: 1fr 1fr;
            grid-gap: 29px 16px;
            align-items: flex-start;
            padding-top: 30px;
            /* height: 255px; */
        }

        @media (min-width: 1200px){
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            padding-top: 0px;
            padding-top: 20px;
            /* height: 110px; */
        }
    }

    .carousel-control-prev,
    .carousel-control-next{
        margin-top: 18px;
    }
`;
import { createGlobalStyle } from 'styled-components';
import ArrowLeftMobile from '../Assets/img/icons/arrow-left-mobile.png';
import ArrowLeftDesktop from '../Assets/img/icons/arrow-left-desktop.png';

import ArrowRightMobile from '../Assets/img/icons/arrow-right-mobile.png';
import ArrowRightDesktop from '../Assets/img/icons/arrow-right-desktop.png';

export default createGlobalStyle`
    :root {
        --type-first: 'Montserrat', sans-serif;
        --type-second: 'Merienda', cursive;

        --color-1: #0093af;
        --color-2: #00803a;

        --n-color-1: #020202;
        --n-color-2: #575756;   
    }


    /* Reset */
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    html{
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    body{
        margin: 0px;
        color: #333331;
        font-family: var(--type-first);
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        line-height: 1.5;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p{
        margin: 0;
    }

    h1, h2, h3, h4, h5, h6{
        font-family: 'Merienda', cursive;
        font-weight: bold;
    }

    ul, li{
        list-style: none;
        padding: 0px;
        margin: 0px;
    }

    img{
        display: block;
        max-width: 100%;
    }

    button,
    input{
        display: block;
        font-size: 1rem;
        font-family: var(--type-first);
        color: #333331;
    }

    a{
        text-decoration: none;
    }

    /* Containers */
    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 0px !important;
    }

    @media (min-width: 576px) {
        .container, .container-sm {
            width: 100%;
            max-width: 540px;
        }
    }

    @media (min-width: 768px) {
        .container, .container-sm, .container-md {
            max-width: 720px;
        }
    }

    @media (min-width: 992px) {
        .container, .container-sm, .container-md, .container-lg {
            max-width: 960px;
        }
    }

    @media (min-width: 1200px) {
        .container, .container-sm, .container-md, .container-lg, .container-xl {
            max-width: 1140px;
        }
    }
    
    hr{
        width: 190px;
        height: 1px;
        background: #cacaca;
        margin: 0 auto 22px;

        @media (min-width: 768px){
            margin: 0 auto 45px;
            height: 3px;
        }
    }

    /* Slideshow */
    .section-slideshow{
        max-width: 340px;
        margin: 0 auto;

        @media (min-width: 768px){
            max-width: 580px;
            margin: 0 auto;
        }

        @media (min-width: 1200px){
            max-width: 870px;
            margin: 0 auto;
        } 
    }

    .carousel-standard{
        .carousel-control-next{
            right: -15px;
            transition: all .3s ease;

            @media (min-width: 768px){
                right: -75px;
            }

            @media (min-width: 1200px){
                right: -110px;
            }

            span{
                border: 1px solid #00803a;
                border-radius: 2px;
                background-image: url(${ArrowRightMobile}) !important;
                background-size: auto;

                @media (min-width: 768px){
                    border: 2px solid #00803a;
                    border-radius: 5px;
                    background-image: url(${ArrowRightDesktop}) !important;
                    width: 40px;
                    height: 45px;
                }
            }

            &:active{
                transform: scale(0.8    );
            }
        }

        .carousel-control-prev{
            left: -15px;
            transition: all .3s ease;

            @media (min-width: 768px){
                left: -75px;
            }

            @media (min-width: 1200px){
                left: -110px;
            }

            span{
                border: 1px solid #00803a;
                border-radius: 2px;
                background-image: url(${ArrowLeftMobile}) !important;
                background-size: auto;

                @media (min-width: 768px){
                    border: 2px solid #00803a;
                    border-radius: 5px;
                    background-image: url(${ArrowLeftDesktop}) !important;
                    width: 40px;
                    height: 45px;
                }
            }
            &:active{
                transform: scale(0.8);
            }
        }

        /* Arrow Bootstrap */
        .sr-only{
            display: none;
        }
    }


    /* skeleton */
    .skeleton{
        width: 120px;
        height: 115px;
        background-image: linear-gradient(90deg, #eee 0px, #fff 50%, #eee 100%);
        background-color: #eee;
        background-size: 200%;  
        animation: skeleton 1.5s infinite linear;
    }

    @keyframes skeleton{
        from{
            background-position: 0px;
        }
        to{
            background-position: -200%;
        }
    }
`;
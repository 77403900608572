import styled from 'styled-components';

export const Section = styled.section`
    .skeleton{
        width: 250px;
        height: 116px;
    }

    .wrap{
        justify-items: center;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;

        @media (min-width: 768px){
            grid-gap: 30px 16px;
            flex-wrap: wrap;
        }
    }
`;
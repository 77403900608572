import firebase from './Assets/js/firebse';
const db = firebase.firestore();

export const getBannerIntro = () => {
    return db.collection("bannerIntro").orderBy("order", "asc").get().then(res => res.docs)
}

export const getDeceaseds = () => {
    const deceaseds = db.collection("deceased");
    const sort = deceaseds.orderBy("name", "asc")
    return sort.get().then(res => res.docs)
}

export const getPartners = () => {
    return db.collection("partners").orderBy("order", "asc").get().then(res => res.docs)
}

export const getTeam = () => {
    return db.collection("team").orderBy("order", "asc").get().then(res => res.docs)
}

export const getMessage = () => {
    return db.collection("message").orderBy("order", "asc").get().then(res => res.docs)
}
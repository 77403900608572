import styled from 'styled-components';

export const H2 = styled.h2`
    font-size: 17px;
    line-height: 17px;
    margin-bottom: 20px;
    color: var(--n-color-2);
    text-align: center;

    @media (min-width: 768px){
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 48px;
    }
`;
import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LandPage from './Page/LandPage';

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={LandPage} />
            </Switch>
        </BrowserRouter>
    )
}


export default Routes



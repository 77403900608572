import React from 'react'
import {ReactComponent as Load} from '../../Assets/img/load-64.svg';
import { GlobalContext } from '../../Global/GlobalContext';
import { Div } from './styles';

const LoadPage = () => {
    
    const { loadingPage } = React.useContext(GlobalContext);

    return (
        <Div show={loadingPage}>
            <div className="content">
                <p>Carregando...</p>
                <Load />
            </div>
        </Div>
    )
}

export default LoadPage

import React from 'react';
import firebase from '../../Assets/js/firebse';
import { Section } from './styles';
import Carousel from 'react-bootstrap/Carousel';
import ImageFirebase from '../ImageFirebase';

const SlideshowPartners = ({data}) => {
    const [device, setDevice] = React.useState(null);
    const [countWrapper, setCountWrapper] = React.useState(null);
    const [countItens, setCountItens] = React.useState(0);
    const [loadActive, setLoadActive] = React.useState(true);
    const [controls, setControls] = React.useState(false);
    const storage = firebase.storage();

    function deviceUse(){
        if (window.matchMedia("(min-width: 1200px)").matches){
            setDevice('desktop');
            setCountItens(7)
            return;
        }
        if (window.matchMedia("(min-width: 768px)").matches){
            setDevice('tablet');
            setCountItens(4)
            return;
        }

        setDevice('mobile');
        setCountItens(2);
    }

    function handleLoad(e){
        let wait = e.target.parentElement.previousSibling;
        wait.parentNode.removeChild(wait);
        setLoadActive(false);
    }

    function validControls(){
        var width = window.innerWidth;

        if(data && data.length > 2 && width < 760)
            setControls(true);

        if(data && data.length > 4 && width > 760 )
            setControls(true);

        // if(data && data.length > 7 && width > 1200 )
        //     setControls(true);
    }

    function countWrapperData(){
        let qnt = countItens;
        const count = [];

        if(data){
            let val = Math.ceil(data.length / qnt);

            for(let i = 0; i < val; i++){
                count.push(i);
            }

            setCountWrapper(count)
        }
    }

    React.useEffect(() => {
        validControls();
        deviceUse()
        countWrapperData();

        window.addEventListener('resize', deviceUse)
        return () => window.removeEventListener("resize", deviceUse);
    }, [data, countItens]);

    if(device === 'mobile')
        return (
            <Section className="section-slideshow">
                <Carousel 
                    className="carousel-standard" 
                    indicators={false} 
                    interval={null}
                    controls={controls}
                >

                    {countWrapper && countWrapper.map((el, indexCarousel) => {
                        let init = 0;
                        let max = 2;
                        let prevCarouselCount = indexCarousel * max;

                        return (
                            <Carousel.Item key={`carousel-item-${indexCarousel}`}>
                                <div className="wrap">
                                    {data && data.map((el, index) => {
                                        const maxItens = (init < max);
                                        const initWhereStop = ((index + 1) > prevCarouselCount);

                                        if(maxItens && initWhereStop){
                                            init ++;
                                            prevCarouselCount++;

                                            return (
                                                <div className="wrap-img" key={index}>
                                                    <div className="skeleton"></div>
                                                    
                                                    <ImageFirebase 
                                                        mobilePhoto={el.data().logo} 
                                                        alt={el.data().name}
                                                        onLoad={handleLoad} 
                                                        loadActive={loadActive}
                                                    />
                                                </div>
                                            )
                                        }

                                        return null;
                                    })}
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </Section>
        );

    // if(device === 'tablet')
    if(countWrapper && countWrapper.length > 0)
        return (
            <Section className="section-slideshow">
                <Carousel 
                    className="carousel-standard" 
                    indicators={false} 
                    interval={null}
                    controls={controls}
                >

                    {countWrapper && countWrapper.map((el, indexCarousel) => {
                        let init = 0;
                        let max = 4;
                        let prevCarouselCount = indexCarousel * max;

                        return (
                            <Carousel.Item key={`carousel-item-${indexCarousel}`}>
                                <div className="wrap">
                                    {data && data.map((el, index) => {
                                        const maxItens = (init < max);
                                        const initWhereStop = ((index + 1) > prevCarouselCount);

                                        if(maxItens && initWhereStop){
                                            init ++;
                                            prevCarouselCount++;

                                            return (
                                                <div className="wrap-img" key={index}>
                                                    <div className="skeleton"></div>
                                                    
                                                    <ImageFirebase 
                                                        onLoad={handleLoad} 
                                                        mobilePhoto={el.data().logo} 
                                                        alt={el.data().name}
                                                        loadActive={loadActive}
                                                    />
                                                </div>
                                            )
                                        }

                                        return null;
                                    })}
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </Section>
        );


    // if(countWrapper && countWrapper.length > 0)
    //     return (
    //         <Section className="section-slideshow">
    //             <Carousel 
    //                 className="carousel-standard" 
    //                 indicators={false} 
    //                 interval={null}
    //                 controls={controls}
    //             >

    //                 {countWrapper && countWrapper.map((el, indexCarousel) => {
    //                     let init = 0;
    //                     let max = 7;
    //                     let prevCarouselCount = indexCarousel * max;

    //                     return (
    //                         <Carousel.Item key={`carousel-item-${indexCarousel}`}>
    //                             <div className="wrap">
    //                                 {data && data.map((el, index) => {
    //                                     const maxItens = (init < max);
    //                                     const initWhereStop = ((index + 1) > prevCarouselCount);

    //                                     if(maxItens && initWhereStop){
    //                                         init ++;
    //                                         prevCarouselCount++;

    //                                         return (
    //                                             <div className="wrap-img" key={index}>
    //                                                 <div className="skeleton"></div>

    //                                                 <ImageFirebase 
    //                                                     onLoad={handleLoad} 
    //                                                     mobilePhoto={el.data().logo} 
    //                                                     alt={el.data().name}
    //                                                     loadActive={loadActive}
    //                                                 />
    //                                             </div>
    //                                         )
    //                                     }

    //                                     return null;
    //                                 })}
    //                             </div>
    //                         </Carousel.Item>
    //                     )
    //                 })}
    //             </Carousel>
    //         </Section>
    //     );

    return null;
}


export default SlideshowPartners

import styled from 'styled-components';

export const Div = styled.div`
    width: 270px;
    background: #def5f7;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: auto;
    /* height: 245px; */

    .wrap-img{
        margin-bottom: 14px;
    }

    h3{
        font-family: var(--type-first);
        font-size: 14px;
        line-height: 14px;
        font-weight: bold;
        margin-bottom: 12px;
    }

    p{
        font-size: 14px;
        line-height: 18px;
    }
`;
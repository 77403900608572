import styled from 'styled-components';

export const Div = styled.section`
    width: 280px;
    background: #def7e9;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px 0px;
    padding: 10px;
    border-radius: 16px;
    height: auto;
    /* height: 180px; */

    .person-photo{
        width: 120px;
        height: 115px;
    }

    .skeleton{
        width: 120px;
        height: 115px;
    }

    .content{
        width: 130px;
    }

    h4{
        font-size: 13px;
        line-height: 16px;
        font-weight: bold;
        font-family: var(--type-first);
        margin-bottom: 6px;
    }

    .date-deceased{
        display: flex;
        justify-content: space-between;
        /* margin-bottom: 6px; */
        margin-top: 32px;

        .date{
            display: flex;
            align-items: center;
        }

        span{
            font-size: 12px;
            line-height: 12px;
            font-weight: bold;
            margin-left: 4px;
        }
    }

    p.text{
        font-size: 14px;
        line-height: 18px;
        grid-column: span 3;
        margin-top: 10px;
    }
`;
import styled from 'styled-components';

export const Main = styled.main`
    
    .slideshow-banner{
        margin: 0 auto 45px;
    
        @media(min-width: 768px){
            margin: 0 auto 90px;
        }
    }
    
    .slideshow-deceased{
        margin-bottom: 22px;

        @media (min-width: 768px){
            margin-bottom: 55px;
        }
    }

    .slideshow-partners{
        margin-bottom: 22px;

        @media (min-width: 768px){
            margin-bottom: 55px;
        }
    }

    .slideshow-team{
        margin-bottom: 22px;

        @media (min-width: 768px){
            margin-bottom: 55px;
        }
    }

    .slideshow-msg{
        margin-bottom: 22px;

        @media (min-width: 768px){
            margin-bottom: 55px;
        }
    }

`;
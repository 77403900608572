import styled from 'styled-components';

export const Div = styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 100;
    background: #fff;
    transition: all .6s ease;
    opacity: ${props => props.show ? '1' : '0'};
    display: ${props => props.show ? 'block' : 'none'};
    /* animation: ${props => props.show ? "show .6s forwards" : ""}; */

    /* @keyframes show{
        0%{
            opacity: 0;
        }

        99%{
            opacity: 1;
        }

        100%{
            display: none;
        }

    } */

    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        margin-top: -60px;
        left: 50%;
        margin-left: -70px;
    }

    p{
        font-size: 22px;
        margin-bottom: 20px;
        color: var(--color-1);
        transition: all 1.5s ease;
        animation: pulse 1.5s ease infinite;
    }

    @keyframes pulse{
        0%{
            opacity: 0;
        }
        50%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
    }
`;
import styled from 'styled-components';

export const Div = styled.section`
    border: 2px solid var(--color-2);
    border-radius: 8px;
    width: 270px;
    padding: 10px;
    position: relative;
    height: auto;
    /* height: 92px; */

    .wrap-icon{
        position: absolute;
        top: -20px;
        right: 10px;
    }

    h3{
        font-size: 12px;
        font-weight: bold;
        font-family: var(--type-first);
        line-height: 12px;
        margin-bottom: 2px;
    }

    .city{
        margin-bottom: 3px;
    }

    p{
        font-size: 12px;
        line-height: 14px;
    }
`;
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import GlobalStyles from './Global/GlobalStyles';
import Routes from './Routes';
import Footer from './Structure/Footer';
import Header from './Structure/Header';
import { GlobalDataContext } from './Global/GlobalContext';
import LoadPage from './Components/LoadPage';


const App = () => {

  return(
    <GlobalDataContext>
        <GlobalStyles />
        <LoadPage />

        <Header />
          <Routes />
        <Footer />
    </GlobalDataContext>
  )
}


export default  React.memo(App);

import styled from 'styled-components';

export const Href = styled.a`
    width: 255px;
    height: 40px;
    background: #def5f7;
    border: 2px solid #0093af;
    text-transform: capitalize;
    font-weight: bold;
    color: #0093af;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease;

    @media (min-width: 992px){
        font-size: 20px;
        width: 390px;
        height: 60px;
    }

    &:hover{
        text-decoration: none;
        color: #0093af;
        opacity: 0.5;
    }
`;
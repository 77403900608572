import styled from 'styled-components';

export const Div = styled.div`
    .carousel-indicators{
        margin-bottom: 5px;

        @media (min-width: 768px){
            margin-bottom: 16px;
        }

        li{
            width: 16px;
            height: 16px;
            border-radius: 100%;
            background: #9c9aa0;
            box-sizing: border-box;
            padding: 0px;
            border: 0px;
            margin: 0px 12px;

            @media (min-width: 768px){
                margin: 0px 18px;
            }
        }
        
        li.active{
            background: var(--color-2);
        }
    }

    .skeleton{
        min-height: 300px;
        width: 100%;
        height: auto;

        @media (min-width: 768px){
            min-height: 400px;
        }
    }
`;
import React from 'react'
import { Link } from 'react-router-dom'
import { Href } from './styles';

const ButtonOne = ({path, children, ...props}) => {
    return (
        <Href href={path} {...props}>
            {children}
        </Href>
    )
}

export default ButtonOne

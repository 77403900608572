import React from 'react';

export const GlobalContext = React.createContext();

export const GlobalDataContext = ( { children } ) => {
    const [loadingPage, setLoadingPage] = React.useState(1);
    
    return (
        <GlobalContext.Provider value={{
            loadingPage,
            setLoadingPage
        }}>
            {children}
        </GlobalContext.Provider>
    )
}
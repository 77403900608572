import React from 'react'

// Styles
import { Main } from './styles';

// Js
import { 
        getBannerIntro, 
        getDeceaseds, 
        getPartners, 
        getTeam, 
        getMessage 
} from '../api';

// Sections
import SlideshowBanner from '../Components/SlideshowBanner';
import SlideshowDeceased from '../Components/SlideshowDeceased';
import SlideshowPartners from '../Components/SlideshowPartners';
import SlideshowTeam from '../Components/SlideshowTeam';
import SlideshowMessage from '../Components/SlideshowMessage';

// Components
import TitleStandard from '../Components/Title/TitleStandard';
import { GlobalContext } from '../Global/GlobalContext';

const LandPage = () => {
    const [bannerIntroData, setBannerIntroData] = React.useState(null);
    const [deceasedsData, setDeceasedsData] = React.useState(null);
    const [partnersData, setPartnersData] = React.useState(null);
    const [teamData, setTeamData] = React.useState(null);
    const [messageData, setMessageData] = React.useState(null);

    const { setLoadingPage } = React.useContext(GlobalContext);

    React.useEffect(() => {
        const getBannerIntroData = getBannerIntro().then(res => setBannerIntroData(res));
        const getDeceasedsData = getDeceaseds().then(res => setDeceasedsData(res));
        const getPartnersData = getPartners().then(res => setPartnersData(res));
        const getTeamData = getTeam().then(res => setTeamData(res));
        const getMessageData = getMessage().then(res => setMessageData(res));

        Promise
            .all([getBannerIntroData, getDeceasedsData, getPartnersData, getTeamData, getMessageData])
            .then(() => setLoadingPage(0) );

    }, []);

    return (
        <Main>
            <section className="slideshow-banner">      
                <SlideshowBanner 
                    data={bannerIntroData}
                />
            </section>

            <section className="slideshow-deceased">
                <div className="container">
                    <TitleStandard>A nossa gratidão a vocês.</TitleStandard>
                    
                    <SlideshowDeceased 
                        data={deceasedsData}
                    />
                </div>
            </section>

            <hr/>

            <section className="slideshow-partners">
                <div className="container">
                    <TitleStandard>Não estamos sozinhos.</TitleStandard>

                    <SlideshowPartners 
                        data={partnersData}
                    />
                </div>
            </section>

            <hr/>

            <section className="slideshow-team">
                <div className="container">
                    <TitleStandard>Vocês fazem toda a diferença.</TitleStandard>

                    <SlideshowTeam 
                        data={teamData}
                    />
                </div>
            </section>
           
            <hr/>

            <section className="slideshow-msg">
                <div className="container">
                    <TitleStandard>Aglomeração virtual</TitleStandard>
                    
                    <SlideshowMessage 
                        data={messageData}
                    />
                </div>
            </section>
        
        </Main>
    )
}

export default React.memo(LandPage);

import React from 'react';
import { Div } from './styles';
import ImageFirebase from '../ImageFirebase';

const CardTeam = ({title, content, image}) => {
    const [loadActive, setLoadActive] = React.useState(true);

    function handleLoad(e){
        let wait = e.target.parentElement.previousSibling;
        wait.parentNode.removeChild(wait);
        setLoadActive(false);
    }

    return (
        <Div>
            <div className="wrap-img">
                <div className="skeleton"></div>
                <ImageFirebase 
                    mobilePhoto={image && image}
                    onLoad={handleLoad} 
                    loadActive={loadActive}
                />
            </div>

            <h3>{title && title}</h3>

            <p>{content && content}</p>
        </Div>
    )
}

export default CardTeam

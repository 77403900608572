import React from 'react'
import Logo from '../../Assets/img/logo2.jpg';
import { FooterDiv } from './styles';

import LocalIcon from '../../Assets/img/icons/local.png';
import MailIcon from '../../Assets/img/icons/mail.png';
import WhatsappIcon from '../../Assets/img/socialmedia/whatsapp.png';
import FacebookIcon from '../../Assets/img/socialmedia/facebook-2.png';
import InstagramIcon from '../../Assets/img/socialmedia/instagram-2.png';
import WebIcon from '../../Assets/img/icons/web.png';
import YoutubeIcon from '../../Assets/img/socialmedia/youtube-2.jpg';


const Footer = () => {
    return (
        <FooterDiv>
            <div className="pt-1">
                <div className="container">
                        <div className="logo">
                            <img src={Logo} alt=""/>
                        </div>

                        <div className="facebook">
                            <iframe 
                                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsociedademedicinacirurgiacampinas%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" 
                                width="339" 
                                height="128" 
                                style={{"border": "none", "overflow": "hidden" }} 
                                scrolling="no" 
                                frameBorder="0" 
                                // allowTransparency="true" 
                                allow="encrypted-media"
                                title="facebook"
                            ></iframe>
                        </div>

                        <ul className="inf">
                            <li>
                                <div className="wrap-img">
                                    <img src={WebIcon} alt=""/>
                                </div>
                                <span>www.smcc.com.br</span>
                            </li>
                            <li>
                                <div className="wrap-img">
                                    <img src={LocalIcon} alt=""/>
                                </div>
                                <span>Rua Delfino Cintra, 63 - Centro  Campinas/SP</span>
                            </li>
                            <li>
                                <div className="wrap-img">
                                    <img src={WhatsappIcon} alt=""/>
                                </div>
                                <span>(19) 3231.2811</span>
                            </li>
                            <li>
                                <div className="wrap-img">
                                    <img src={MailIcon} alt=""/>
                                </div>
                                <span>comunicacao@smcc.com.br</span>
                            </li>
                        </ul>

                        <ul className="social-media">
                            <li>
                                <a 
                                    href="https://www.facebook.com/sociedademedicinacirurgiacampinas/" 
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={FacebookIcon} alt=""/>
                                </a>
                            </li>
                            <li>
                                <a 
                                    href="https://www.instagram.com/smcccampinas/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={InstagramIcon} alt=""/>
                                </a>
                            </li>
                            <li>
                                <a 
                                    href="https://www.youtube.com/channel/UCI81fQOjNYWbh2bZGMR8RbQ"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={YoutubeIcon} alt=""/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="pt-2">
                    <div className="container">
                        <p>Copyright © 2020 Fênix Conteúdo. Todos os 
                            direitos reservados. | <b>Design:</b> Luigi Val 
                            <b> Desenvolvedor:</b> Fábio Chiquezi</p>
                    </div>
                </div>
        </FooterDiv>
    )
}

export default  React.memo(Footer);

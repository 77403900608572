import React from 'react';
import firebase from '../../Assets/js/firebse';
import { Section } from './styles';
import Carousel from 'react-bootstrap/Carousel';
import CardDeceased from '../CardDeceased';


const SlideshowDeceased = ({data}) => {
    const [device, setDevice] = React.useState(null);
    const [countWrapper, setCountWrapper] = React.useState(null);
    const [controls, setControls] = React.useState(false);
    const storage = firebase.storage();
    
    function deviceUse(){
        if (window.matchMedia("(min-width: 768px)").matches){
            setDevice('desktop');
            return;
        }
        setDevice('mobile');
    }

    function validControls(){
        var width = window.innerWidth;

        if(data && data.length > 1 && width < 760)
            setControls(true);

        if(data && data.length > 6 && width > 760 )
            setControls(true);
    }

    
    function countWrapperData(){
        let qnt = 6;
        const count = [];

        if(data){
            let val = Math.ceil(data.length / qnt);

            for(let i = 0; i < val; i++){
                count.push(i);
            }

            setCountWrapper(count)
        }
    }

    React.useEffect(() => {
        validControls();        
        deviceUse()
        countWrapperData();

        window.addEventListener('resize', deviceUse)

        return () => window.removeEventListener("resize", deviceUse);
    }, [data]);

    if(device === 'mobile')
        return(
            <Section className="section-slideshow">
                <Carousel 
                    controls={controls}
                    className="carousel-standard" 
                    indicators={false}
                    interval={null}
                >
                    {data && data.map( (el, index) => {
                        return (
                            <Carousel.Item key={index}>
                                <div className="wrap">
                                    <CardDeceased 
                                        name={el.data().name} 
                                        alt={el.data().name} 
                                        birthday={el.data().birthday}
                                        deceased={el.data().defeat}
                                        content={el.data().content}
                                        image={el.data().image}
                                    />
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </Section>
        );


    if(countWrapper && countWrapper.length > 0)
        return (
            <Section className="section-slideshow">
                <Carousel 
                    controls={controls}
                    className="carousel-standard" 
                    indicators={false} 
                    interval={null}
                >

                    {countWrapper && countWrapper.map((el, indexCarousel) => {

                        let init = 0;
                        let max = 6;
                        let prevCarouselCount = indexCarousel * max;

                        return (
                            <Carousel.Item key={`carousel-item-${indexCarousel}`}>
                                <div className="wrap">

                                    {data.map((el, index) => {
                                        if( (init < max) && ((index + 1) > prevCarouselCount) ) {
                                            init ++;
                                            prevCarouselCount++;

                                            return (
                                                <CardDeceased 
                                                    key={index} 
                                                    name={el.data().name} 
                                                    alt={el.data().name} 
                                                    birthday={el.data().birthday}
                                                    deceased={el.data().defeat}
                                                    content={el.data().content}
                                                    image={el.data().image}
                                                />
                                            )
                                        }

                                        return null;
                                    })}

                                </div>
                            </Carousel.Item>
                        )

                    })}
                </Carousel>
            </Section>
        );

    return null;
}


export default SlideshowDeceased

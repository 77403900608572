import styled from 'styled-components';

export const HeaderDiv = styled.header`
    padding: 18px 0px;

    @media (min-width: 768px){
        padding: 20px 0px 32px;
    }

    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (min-width: 768px){
            padding: 20px 0px 32px;
            flex-direction: row;
            justify-content: space-between;
        }
    }


    h1{
        font-size: 17px;
        text-align: center;
        margin-bottom: 26px;
        color: #575756;

        @media (min-width: 768px){
            text-align: left;
            font-size: 20px;
            margin-bottom: 0px;
        }

        @media (min-width: 1200px){
            font-size: 36px;
        }
    }
    
    .logo{
       max-width: 220px;
       
       @media (min-width: 1200px){
           max-width: 310px;
       }
    }
`;
import styled from 'styled-components';

export const Section = styled.section`
    .wrap{
        display: flex;
        justify-content: center;
        width: 100%;
        grid-gap: 0px 8px;
        @media (min-width: 768px){
            grid-gap: 0px 24px;
        }
    }

    .wrap-img{
        max-width: 133px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 768px){
            max-width: 217px;
        }
    }

    img, picture{
        width: 100%;
    }
`;
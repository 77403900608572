import React from 'react';
import firebase from '../../Assets/js/firebse';

import { Div } from './styles';
import Carousel from 'react-bootstrap/Carousel';

import ImageFirebase from '../ImageFirebase';

const SlideshowBanner = ({data}) => {
    const [indicators, setIndicators] = React.useState(false);
    const [controls, setControls] = React.useState(false);
    const [loadActive, setLoadActive] = React.useState(true);
    const storage = firebase.storage();

    function validControls(){
        if(data && data.length > 1){
            setIndicators(true);
            setControls(true);
            return true;
        }

        return false;
    }

    React.useEffect(() => {
        const configSlideshow = validControls();

        function handleResize(){
            if (window.matchMedia("(min-width: 768px)").matches){
                if(!controls && configSlideshow) setControls(true);
                return
            }

            if(controls) setControls(false);
        }
        handleResize();

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [data]);

    function handleLoad(e){
        let wait = e.target.parentElement.parentElement.previousSibling;
        wait.style.display = 'none';
        setLoadActive(false);
    }

    function handleLink(e){
        let link = e.target.parentElement.parentElement.getAttribute('href');
        if(!link.length) e.preventDefault();
    }

    return(
        <Div>
            
            <Carousel 
                controls={controls} 
                indicators={indicators}
                interval={null} 
            >
                {data && data.map((el, index) => 
                    <Carousel.Item key={index}>
                        
                        <div className="skeleton"></div>

                        <a href={el.data().link} onClick={handleLink} >
                            <ImageFirebase 
                                mobilePhoto={el.data().imageMobile}
                                tabletPhoto={el.data().imageDesktop}
                                alt={el.data().name}
                                onLoad={handleLoad}
                                loadActive={loadActive}
                            />
                        </a>

                    </Carousel.Item>    
                )}
            </Carousel>
            
        </Div>
    );
}

export default SlideshowBanner

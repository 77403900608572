import React from 'react';
import { Picture } from './styles';

const ImageFirebase = ({
    mobilePhoto, 
    tabletPhoto, 
    desktopPhoto, 
    onLoad, 
    loadActive,
    alt
}) => {

    return (
        <Picture loadActive={loadActive}>
            {desktopPhoto && 
                <source 
                    srcSet={desktopPhoto}
                    media="(min-width: 1200px)"
                />
            }

            {tabletPhoto &&  
                <source 
                    srcSet={tabletPhoto}
                    media="(min-width: 768px)"
                />
            }

            <img
                className="d-block w-100"
                onLoad={onLoad}
                src={mobilePhoto}
                alt={alt}
                title={alt}
            />
        </Picture>
    )
}

export default React.memo(ImageFirebase);

import React from 'react';
import { Div } from './styles';

import Cross from '../../Assets/img/icons/cross.png';
import Star from '../../Assets/img/icons/star.png';
import ImageFirebase from '../ImageFirebase';

const CardDeceased = ({name, birthday, deceased, content, image}) => {
    const [loadActive, setLoadActive] = React.useState(true);

    function handleLoad(e){
        let wait = e.target.parentElement.previousSibling;
        wait.parentNode.removeChild(wait);
        setLoadActive(false);
    }

    return (
        <Div>
            <div className="wrap-image person-photo">
                <div className="skeleton"></div>

                <ImageFirebase 
                    mobilePhoto={image} 
                    onLoad={handleLoad} 
                    alt={name}
                    loadActive={loadActive}
                />
            </div>

            <div className="content">
                <h4 className="title-standard">{name}</h4>

                <div className="date-deceased">
                    <div className="birth date">
                        <div className="wrap-image">
                            <img src={Star} alt=""/>
                        </div>

                        <span>{birthday}</span>
                    </div>

                    <div className="death date">
                        <div className="wrap-image">
                            <img src={Cross} alt=""/>
                        </div>
                        <span>{deceased}</span>
                    </div>
                </div>
            </div> 

            <p className="text">
                    {content}
                </p>
        </Div>
    )
}

export default CardDeceased

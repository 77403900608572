import styled from 'styled-components';

export const FooterDiv = styled.footer`
    background: var(--color-1);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

    a{
        color: #fff;
    }

    .pt-1{
        padding: 20px 0px !important;
        width: 100%;

        @media (min-width: 992px){
            padding: 32px 0px !important;
        }

        .container{
            @media(min-width: 992px){
                display: grid;
                grid-template-columns: 200px 2fr 340px;
                grid-gap: 0px 32px
            }

            @media (min-width: 1200px){
                grid-gap: 0px 100px;
            }
        }
    }

    .logo{
        margin-bottom: 20px;
        
        img{
            margin: 0 auto;

            @media(min-width: 992px){
                margin: 0px;
            }
        }
    }

    .facebook{
        margin-bottom: 25px;
        text-align: center;
        display: flex;
        justify-content: center;

        @media(min-width: 992px){
            grid-column: 3;
            text-align: right;
        }
    }
    /* @media(min-width: 992px){
  
    } */
    .inf{
        text-align: center;
        margin-bottom: 20px;

        @media(min-width: 992px){
            grid-column: 2;
            grid-row: 1;
        }

        li{
            margin-bottom: 10px;
            display: flex;
            justify-content: center;

            @media (min-width: 992px){
                justify-content: flex-start;
                text-align: left;
                font-size: 16px;
                margin-bottom: 25px !important;
            }

            .wrap-img{
                margin-right: 10px;
                position: relative;
                top: 3px;
            }

            &:last-child{
                margin-bottom: 0px;
            }
        }

        li:first-child{
            width: 265px;
            margin: 0 auto 10px auto;

            @media (min-width: 992px){
                width: 100%;
            }

            .wrap-img{
                top: 5px;

                @media (min-width: 992px){
                    top: 2px;
                }
            }
        }

        li:nth-child(2){
            .wrap-img{
                position: relative;
                right: -12px;

                @media (min-width: 992px){
                    right: 0px;
                }
            }
            span{
                max-width: 267px;
            
                @media (min-width: 992px){
                    max-width: auto;
                }
            }
        }
    }

    .social-media{
        display: flex;
        justify-content: center;

        @media(min-width: 992px){
            grid-column: 2;
            grid-row: 2;
            justify-content: flex-start;
            margin-top: -10px;
        }

        li + li{
            margin-left: 8px;
        }
        
        img{
            transition: all .3s ease;
        }
        a:hover{
            img{
                opacity: 0.5 !important;
            }
        }
    }

    .pt-2{
        background: #07798f;
        width: 100%;
        padding: 20px 0px !important;
        font-size: 12px;

        @media (min-width: 992px){
            padding: 32px 0px !important;
        }
    }
`;
import React from 'react';
import { Div } from './styles';
import Twitter from '../../Assets/img/socialmedia/twitter.png'
import Facebook from '../../Assets/img/socialmedia/facebook.png'
import Instagram from '../../Assets/img/socialmedia/instagram.png'

const CardMessage = ({title, city, content, socialMedia}) => {
    const [useSocialMedia, setUseSocialMedia] = React.useState(null);

    React.useEffect(() => {
        if(socialMedia == "facebook") setUseSocialMedia(Facebook);
        if(socialMedia == "instagram") setUseSocialMedia(Instagram);
        if(socialMedia == "twitter") setUseSocialMedia(Twitter);

    }, [socialMedia]);
    return (
        <Div>
            <div className="wrap-icon">
                <img src={useSocialMedia} alt=""/>
            </div>
            <h3>{title}</h3>
            <p className="city">{city}</p>
            <p className="content">{content}</p>
        </Div>
    )
}

export default CardMessage
